#evidon-prefdiag-navcontainer {
    background-color: #008e77 !important;
}

#_evidon-decline-button {
    color: #fff !important;
}

/* Request Your Data */
#evidon-prefdiag-nav-1 {
    display: none !important;
}

body {
    overflow: auto;
}
